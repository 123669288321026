.location-holder {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 6px !important;
  max-width: 40%;
}

.location-div {
  border-radius: 5px;
  padding: 2px 10px;
  background-color: rgba(0, 0, 0, 0.807);
  color: white;
}

.location-div p {
  margin-bottom: 0;
}

.location-div p > span {
  color: #00bfff;
}
