@mixin fade-transition($element) {
	-webkit-transition: $element 0.15s ease-in-out;
	-moz-transition: $element 0.15s ease-in-out;
	-ms-transition: $element 0.15s ease-in-out;
	-o-transition: $element 0.15s ease-in-out;
	transition: $element 0.15s ease-in-out;
}


.kyc-dialog {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.275);
  backdrop-filter: blur(2px);
  z-index: 6000;
  top: 0;

  .welcome {
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 500;
    color: rgb(27, 92, 203);
  }

  .name {
    margin-bottom: 0px;
    font-size: 30px;
    font-weight: 500;
  }

  .dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: auto;
    background: white !important;
    border-radius: 10px;
    padding: 20px;
  }

  .steps-head {
    color: rgb(8, 1, 29);
    font-weight: 500;
  }
  .desc {
    color: gray;
    width: 90%;
    margin-top: 20px;
  }
  button {
    box-shadow: none !important;
    border-radius: 30px;
    padding: 10px 30px;
  }
}

.card {
  .card-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;

    p {
      margin-bottom: 0;
      font-size: 20px;
    }
  }
}

.home-btn {
  width: 100%;
  height: 70px;
  text-transform: none !important;
  font-size: 18px !important;
  background-color: #573a7a !important;
  color: white !important;
  font-weight: 300 !important;
  border-radius: 20px !important;
}

.name-home {
  font-size: 22px;
  color: rgb(0, 0, 0);
  font-weight: 500;
}

.card-home {
  position: relative;
  width: 100%;
  height: 200px;
  background: #be9fe1;
  border-radius: 18px;
  box-shadow: -5px 8px #333333;
  padding: 20px;

  .heading {
    font-size: 15px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
  }

  .desc-text {
    font-size: 15px;
    font-weight: 400;
    color: white;
  }

  .button-holder {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .btn {
    background: #333333 !important;
    color: white !important;
    box-shadow: none !important;
    text-transform: none !important;
    border-radius: 12px !important;
  }
}

.create-card {
  /*  styling */
  display: inline-block;
  padding: 25px;
  // border: 3px solid #fafafa;

  @include fade-transition(background);


  .create-button {
    position: absolute;
    bottom: 20px;
    padding: 10px 25px !important;
    z-index: 1000;
  }


}

.change-lang-btn-mob{
  display: none !important;
}

.change-lang-btn{
  position: relative !important;
  color: #333333 !important;
  border: 1.5px solid #333333 !important;
  text-transform: none !important;
  border-radius:  1 !important;
}

.change-lang-btn img{
  position: absolute;
  top: -50%;
  left: 10px;
  background-color: white;
}

i {
  /*   positioning */
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;

  /*   gradient   */
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(1%, rgba(255, 255, 255, 0.03)),
    color-stop(30%, rgba(255, 255, 255, 0.85)),
    color-stop(50%, rgba(255, 255, 255, 0.85)),
    color-stop(70%, rgba(255, 255, 255, 0.85)),
    color-stop(71%, rgba(255, 255, 255, 0.85)),
    color-stop(100%, rgba(255, 255, 255, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

  /*  forming the shine element
      play around with the width, skew and gradient to get different effects
    */
  width: 15%;
  height: 100%;
  transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  -moz-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);

  /*  animating it  */
  animation: move 2s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  -webkit-animation: move 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 1s;
  -moz-transform: skew(-10deg, 0deg);
  -moz-animation: move 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-delay: 1s;
  -ms-transform: skew(-10deg, 0deg);
  -ms-animation: move 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-delay: 1s;
  -o-transform: skew(-10deg, 0deg);
  -o-animation: move 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-delay: 1s;
}

/*  */
@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

@-webkit-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

@-moz-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 88%;
  }
}

@-ms-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

@-o-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}
