.notification-card {
  width: 100%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 15px;

  .noti-heading {
    margin-bottom: 10px;
    color: #77747b;
    font-size: 20px;
    font-weight: 600;
  }

  .noti-body {
    margin-bottom: 25px;
  }

  .noti-time {
    margin-bottom: 0;
    color: #88848c;
  }
}

.notification-not-read{
  box-shadow: rgba(194, 30, 30, 0.601) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
}

.noti-pop-up {
  .noti-heading {
    font-weight: 500;
  }
}



@media only screen and (max-width: 600px) {
  .noti-pop-up {
    .right-content {
      padding-left: 0 !important;
    }
  }
}


// Pop-up
strong {
	font-weight: 600;
}
.notification {
	width: 360px;
	padding: 15px;
	background-color: white;
	border-radius: 16px;
	position: fixed;
	// top: 15px;
  top: -600px;
	right: 15px;
  z-index: 10000;
  transition: all 0.5s ease-in-out;
	animation: noti 2s infinite forwards alternate ease-in;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  &-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;
	}
	&-title {
		font-size: 16px;
		font-weight: 500;
		text-transform: capitalize;
	}
	&-close {
		cursor: pointer;
		width: 30px;
		height: 30px;
		border-radius: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #F0F2F5;
		font-size: 14px;
	}
	&-container {
		display: flex;
    align-items: flex-start;
	}
	&-media {
		    position: relative;
	}
	&-user-avatar {
		width: 60px;
    height: 60px;
    border-radius: 60px;
    object-fit: cover;
	}
	&-reaction {
		width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    color: white;
    background-image: linear-gradient(45deg, #0070E1, #14ABFE);
    font-size: 14px;
    position: absolute;
    bottom: 0;
    right: 0;
	}
	&-content {
		width: calc(100% - 60px);
    padding-left: 20px;
    line-height: 1.2;
	}
	&-text {
		    margin-bottom: 5px;
		display:-webkit-box;
-webkit-line-clamp:3;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
		padding-right: 50px;

	}
	&-timer {
		color: #1876F2;
    font-weight: 600;
    font-size: 14px;
	}
	&-status {
		position: absolute;
    right: 15px;
    top: 50%;
    // /* transform: translateY(-50%); */
    width: 15px;
    height: 15px;
    background-color: #1876F2;
    border-radius: 50%;
	}
}

.noti-popup-open{
  top: 15px !important;
}
