@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&family=Manrope:wght@200;300;400;500&display=swap');

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
}

code {
  font-family: 'Manrope', sans-serif;
}

body, html {
  height:100%;
}