.reset-wrapper{
    .heading{
        font-size: 30px;
        font-weight: 500;
    }

    .desc{
        font-size: 14px;
        color: rgb(71, 71, 71);
    }

    button {
        width: 100%;
        height: 50px;
        box-shadow: none !important;
        border-radius: 20px;
    }
}