.tab-fixed {
  position: fixed;
  width: 100% !important;
  z-index: 1000 !important;
}
.tabs-wrapper {
  background-color: rgba(243, 245, 250, 1) !important;
  height: 120px;

  .app-bar {
    display: flex;
    align-items: center;
  }

  .tab {
    color: #573A7A !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    padding-top: 0 !important;
  }

  .active {
    background-color: #573A7A !important ;
    color: white !important;
  }
}

.collapse-container {
  border: 2px solid rgba(39, 67, 132, 1) !important;
  border-radius: 10px !important;
  margin-bottom: 15px;
  background-color: rgba(243, 245, 250, 1) !important;

  .collapse-head {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 17px;
    color: rgba(39, 67, 132, 1) !important ;
  }

  .header {
    position: relative !important;
    position: fixed;
    padding-left: 20px;
    padding-top: 10px;
    font-size: 20px;
  }

  .close-btn {
    position: fixed;
    right: 20px;
    top: 10px;
    cursor: pointer;
  }
}

.scrollable {
  overflow-y: scroll !important;
  max-height: 80vh !important;
}

.collapse-column {
  .head {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    color: rgba(39, 67, 132, 1) !important ;
  }
  .desc {
    color: rgba(39, 67, 132, 1) !important ;
    margin-left: 20px;
    font-size: 14px;
  }
  .transactions-table {
    width: 100%;
    border: 1.5px solid rgba(39, 67, 132, 1) !important;
    max-height: 300px;
    overflow: scroll;
  }
}

.profile-section {
  .name {
    text-align: center !important;
    color: rgba(39, 67, 132, 1) !important ;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
  }
  .mobile {
    text-align: center !important;
    color: rgba(39, 67, 132, 1) !important ;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
  }
  .email {
    text-align: center !important;
    color: rgba(39, 67, 132, 1) !important ;
    margin-bottom: 0;
    font-size: 16px;
  }
}

.loan-head {
  font-weight: 500;
  color: rgba(39, 67, 132, 1) !important ;
}

.view-backdrop {
  position: fixed;
  width: 100%;
  z-index: 1000;
  padding: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.collect-modal {
  position: fixed;
  width: 100%;
  z-index: 5000;
  padding: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.collect-modal-backdrop {
  z-index: 4500 !important;
}

.image-card {
  width: 100px;
  margin-top: 10px;
  border-radius: 10px;
  height: 80px;
  margin-bottom: 20px;
}

.profile-pic {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}

.collect-button {
  width: 100% !important;
  height: 40px !important;
  font-size: 9px !important;
  color: rgba(131, 131, 136, 1) !important;
  border: 2px solid rgba(131, 131, 136, 1) !important;
}

.collect-btn-green {
  color: #22884b !important;
  border: 2px solid #22884b !important;
}

.collect-btn-blue {
  color: rgba(39, 67, 132, 1) !important;
  border: 2px solid rgba(39, 67, 132, 1) !important;
}

.collect-btn-red {
  color: #cf2828 !important;
  border: 2px solid #cf2828 !important;
}
.collect-btn-confirm {
  color: white !important;
  font-size: 15px !important;
  background-color: #22884b !important;
  border: 2px solid #22884b !important;
}
