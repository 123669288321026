.select-style {
  padding: 0;
  margin: 0;
  border: none;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.select-bg-white{
  background-color: #ffffff !important;
}

.select-bg-gray{
  background-color: #f3f8fd !important;
}

.select-blue {

  height: 35px !important;
  /* border: 1px solid #2f60d1 !important; */
  border-radius: 3px !important;
  color: rgb(172, 172, 172) !important;
  /* background-color: #f3f8fd !important; */
  
}

.select-style select {
  padding: 5px 18px;
  width: 100%;
  height: 50px;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


.select-style:after {
  top: 50%;
  left: 92%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-width: 5px;
  margin-top: -2px;
  z-index: 100;
}
.select-style select:focus {
  outline: none;
}

.select-style option[default] {
  display: none;
}

.select-style select > option:not(:first-of-type) {
  color: black;
  font-size: 16px;
}
