.rf-parent {
    height: 310px;
    top: 0px;
    background: #6C63FF;
    box-shadow: 6px 6px 30px rgba(125, 153, 180, 0.2);
    border-radius: 0px 0px 24px 24px;
}

.rf-head {
    text-align: right;
}

.rf-close-text {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    padding-top: 0.7em;
    padding-right: 0.7em;
}

.rf-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #fff;
}

.rf-sub-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #EEEEEE;
    text-align: center;
    margin-top: 1.5em;
}

.rf-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.rf-card-left {
    display: flex;
}

.rf-card-title-wrapper {
    padding-left: 0.7em;
    padding-right: 0.7em;
}

.rf-card-title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #000000;
}

.rf-card-subtitle {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #6F6F6F;
}

.rf-card-figure {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #6C63FF;
}

.rf-logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rf-logo {
    margin: 3em;
}

.rf-note {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #4D61FC;
    text-align: center;
    margin-top: 1.5em;
}